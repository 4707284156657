/** @jsx jsx */
import React from "react"
import { jsx, Link as TLink } from "theme-ui"

type NavigationProps = {
  nav: {
    title: string
    slug: string
    url: string
  }[]
}

const Navigation = ({ nav }: NavigationProps) => {

  return (
    <React.Fragment>
      {nav && nav.length > 0 && (
        <nav sx={{ "a:not(:last-of-type)": { mr: 3 }, fontSize: [1, `18px`], ".active": { color: `heading`, fontWeight: "bold" } }}>
          {/* Add back the to={replaceSlashes(`/${basePath}/${item.slug} field for relative paths */}
          {nav.map((item) => (
            <TLink key={item.slug} href={item.url}>
              {item.title}
            </TLink>
          ))}
        </nav>
      )}
    </React.Fragment>
  )
}

export default Navigation